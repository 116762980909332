import * as React from "react"

import Layout from "src/layouts/en"
import Seo from "src/components/seo"

const NotFoundPage = (props) => (
  <Layout location={ props.location }>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
